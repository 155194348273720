@use './variables' as site

@mixin roundButton
  transition: all 0.2s ease-out
  cursor: pointer
  color: site.$buttonPrimaryColor
  border: 1px dashed site.$buttonPrimaryColor
  border-radius: 25px
  padding: 6px 12px
  display: inline-block
  overflow-wrap: break-word
  word-wrap: break-word
  hyphens: auto
  max-width: site.$minWidth / 2 - 62
  outline: none

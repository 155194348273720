//css for reset page
@use './variables' as site
@import url('https://www.ft.com/__origami/service/build/v2/bundles/css?modules=o-fonts@^3')

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  font: inherit
  vertical-align: baseline

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section
  display: block

html
  height: 100%

body
  min-width: site.$minWidth / 2
  min-height: 100%
  background: site.$backgroundColor
  color: site.$fontColor
  font-family: FinancierDisplayWeb, sans-serif
  @media screen and (max-width: site.$minWidth / 2)
    background: #fff
    height: 200%

input
  font-family: FinancierDisplayWeb, sans-serif

ol, ul
  list-style: none

blockquote, q
  quotes: none

blockquote:before, blockquote:after,
q:before, q:after
  content: ''
  content: none

table
  border-collapse: collapse
  border-spacing: 0

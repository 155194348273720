@use '../../styles/variables' as site

.terminalBar
  position: fixed
  left: 0
  top: 0
  z-index: 1
  height: 100%
  width: 30px
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  .openButton
    width: 24px
.terminalBar:hover
  cursor: e-resize
  background: rgba(255,255,255,0.8)
  -webkit-box-shadow: 0 0 18px 1px rgba(0,0,0,0.5)
  -moz-box-shadow: 0 0 18px 1px rgba(0,0,0,0.5)
  box-shadow: 0 0 18px 1px rgba(0,0,0,0.5)

.terminal,.terminal_loading
  -webkit-box-shadow: 0 0 18px 1px rgba(0,0,0,0.5)
  -moz-box-shadow: 0 0 18px 1px rgba(0,0,0,0.5)
  box-shadow: 0 0 18px 1px rgba(0,0,0,0.5)
  z-index: 1
  position: fixed
  left: 0
  top: 0
  height: 100%
  background: site.$workingBG
  padding: 20px
  width: site.$minWidth / 2 - 40
  display: flex
  flex-direction: column
  input, textarea
    margin-bottom: 1em
    width: site.$minWidth / 2 - 52
    padding: 5px
    font-size: 1.2em
    line-height: 1.5em
    border: site.$fontColor 1px dashed
    outline: none
  textarea
    background: none
    flex-grow: 1
    margin-bottom: 20px
    resize: none
  .closeButton
    cursor: pointer
    margin-left: site.$minWidth / 2 - 72
    display: inline-block
    flex-grow: 0
    flex-shrink: 0
    flex-basis: auto
    width: 36px
    height: 36px
    margin-bottom: 10px

.terminal_loading
  animation: bgg 2000ms ease-in alternate infinite

@keyframes bgg
    0%
      background: site.$backgroundColor
    30%
      background: site.$fontColorGold
    100%
      background: site.$backgroundColor


@use './variables' as site

a,a:hover,a:visited
  text-decoration: none
  color: site.$fontColorLink
  transition: all 0.2s ease-out

a:hover
  color: site.$fontColorGrey

h1
  border-top: 8px solid #000
  font-style: normal
  font-weight: bold
  font-size: 2em
  line-height: 1.6em
  margin: 0.2em 0 0.2em

h2
  font-style: normal
  font-weight: bold
  font-size: 1.5em
  line-height: 1.6em
  margin: 0.2em 0 0.2em

h3
  font-style: normal
  font-weight: bold
  font-size: 1.1em
  line-height: 1.2em
  margin: 0.2em 0 0.2em

p, li
  font-style: normal
  font-weight: normal
  font-size: 1em
  line-height: 1.5em

code
  display: inline
  margin: 0
  padding: 0.1em 0.3em
  background: site.$codeBGcolor
  border-radius: 3px
  font-size: 1em
  line-height: 1.5em
  max-width: 95%

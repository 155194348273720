@use '../../styles/variables' as site
@use '../../styles/mixin' as mixin

.appPage
  font-family: "Lucida Grande",fangsong
  font-size: 48px
  position: fixed
  header
    background: url(apple.svg) center -3px rgb(29, 29, 31) no-repeat
    background-size: 50px
    height: 120px
  .title
    padding: 0.4em 0.5em
    font-weight: normal
    font-size: 64px
    border-bottom: #A5A5A5 1px solid
    justify-content: space-between
    .me
      float: right
      width: 75px
      fill: rgb(0, 113, 235)
      height: 75px
  .menu
    width: 100px
    height: 80px
    padding: 25px 0 0 25px
    ul
      margin-top: 20px
      background: #fff
      width: 45px
      height: 2px
  .body
    ul
      padding: 2em 2em 0
      text-align: center
    .icon
      width: 200px
      fill: rgb(0, 122, 255)
    .email
      color: rgb(0, 102, 204)
    .button
      text-align: center
      width: 280px
      margin: 120px auto 0
      border-radius: 24px
      background: #e8e8ed
      padding: 12px 40px


@use '../../styles/variables' as site
@use '../../styles/mixin' as mixin

.foundation,.foundationInEditMode
  width: site.$minWidth / 2 - 40
  padding: 20px
  font-size: 1.2em
  line-height: 2em
  margin: auto
  p
    margin-bottom: 0.5em

.foundationInEditMode
  float: left
  margin-left: site.$minWidth / 2

@use '../../styles/variables' as site
@use '../../styles/mixin' as mixin

.clickableLine
  @include mixin.roundButton
.clickableLine:hover
  background: site.$buttonHoverColor

.button,.buttonOption
  @include mixin.roundButton
  border-color: site.$inputBG
  background: site.$inputBG
  color: site.$inputFontColor
.button:hover
  background-color: site.$inputBGhover
.buttonOption
  background: none
  color: site.$fontColor
.link
  @include mixin.roundButton
  border-color: site.$linkBG
  background: site.$linkBG
  color: site.$inputFontColor
.link:hover
  background-color: site.$linkBGhover
input
  @include mixin.roundButton
  font-size: 1em
  height: 1.5em
  border: none
  background: site.$inputBG
  color: site.$inputFontColor
  width: site.$inputFieldWidth
  display: block
input:hover
  background-color: site.$inputBGhover

.cloudStorage
  margin-bottom: 12px
.inlineButtons, .inlineOptions
  margin-bottom: 0.5em
  p, input
    display: inline
    margin-right: 0.66em
  .priorityInput
    text-align: center
    width: 3%
ol
  list-style-type: upper-roman

.markdown
  width: 100%
  blockquote
    border-left: 10px solid site.$fontColorGold
    padding: 5px 10px
    p
      margin-bottom: 0
  strong
    font-weight: bold
  ul
    list-style: disc

.image
  display: flex
  justify-content: space-between

  ul
    width: 48%

.image img, .homer img,.homer video, .markdown img
  max-width: 100%
  border-radius: 12px

.ending img
  max-width: 100%

.timeBox
  color: site.$fontColorGold
  text-align: center
  font-weight: bold
  font-size: 240px
  line-height: 240px
  height: 240px
  z-index: 0
  position: fixed
  bottom: 0
  right: 15px
  opacity: 0.11
  animation: color-change 10s infinite

@keyframes color-change
  0%
    opacity: 0.11
  30%
    opacity: 0.88
  100%
    opacity: 0.11

.typing
  display: inline-block
  position: relative
  width: 80px
  height: 80px
.typing div
  position: absolute
  top: 33px
  width: 13px
  height: 13px
  border-radius: 50%
  background: site.$buttonPrimaryColor
  animation-timing-function: cubic-bezier(0, 1, 1, 0)
.typing div:nth-child(1)
  left: 8px
  animation: typing1 0.6s infinite

.typing div:nth-child(2)
  left: 8px
  animation: typing2 0.6s infinite

.typing div:nth-child(3)
  left: 32px
  animation: typing2 0.6s infinite

.typing div:nth-child(4)
  left: 56px
  animation: typing3 0.6s infinite

@keyframes typing1
  0%
    transform: scale(0)
  100%
    transform: scale(1)

@keyframes typing3
  0%
    transform: scale(1)
  100%
    transform: scale(0)

@keyframes typing2
  0%
    transform: translate(0, 0)
  100%
    transform: translate(24px, 0)

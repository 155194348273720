$backgroundColor: #fff1e5

$fontColor: #33302e
$fontColorGrey: #A5A5A5
$fontColorGold: #FCD0AB
$fontColorLink: #990f3d

$buttonPrimaryColor: #990f3d
$buttonPrimaryFontColor: #FFFFFF
$buttonSecondaryColor: #4A4A4D
$buttonHoverColor: #fff8f2

$codeBGcolor: #d8f0a2
$blackBGcolor: #262A29

$minWidth: 1680px

$inputFieldWidth: 61.8%

$workingBG: #FFFFFF

$inputBG: #262a33
$inputBGhover: #5d6168
$inputFontColor: #FFFFFF

$linkBG: #0d7680
$linkBGhover: #095259
